import {
    STANDARD_SHIPPING_STATES
} from "../constants"

export const isMultipleSubscriptionQty = (basket) => {
    const optInJson = basket?.c_subscriptionOptins ? JSON.parse(basket.c_subscriptionOptins) : []
    return basket?.productItems?.filter(item => optInJson?.some(optIn => optIn?.product === item?.productId && item?.quantity > 1))?.length
}

export const isAlreadySubscribed = (basket, subscribedProducts, subscriptionMappingString) => {
    if (!subscribedProducts || !subscriptionMappingString || !basket) {
        return false
    }

    let subscriptionMappingArray = []
    let subscribedProductsArray = subscribedProducts.split(',');

    try {
        subscriptionMappingArray = JSON.parse(subscriptionMappingString)
    } catch (e) {
        console.error('Error occurred in parsing subscription mapping string')
    }

    if (!subscriptionMappingArray?.length) {
        return false
    }

    for (let group of subscriptionMappingArray) {
        let subscriptionProductIDs = group?.productIDs?.replace(/\s+/g, '')?.split(',')
        for (let productItem of basket?.productItems) {
            if (isSubscriptionLineItem(basket?.c_subscriptionOptins, productItem?.productId)) { // Only check for products that user is trying to subscribe
                var itemExistsInGroup = subscriptionProductIDs?.includes(productItem?.productId)
                var groupAlreadySubscribed = subscribedProductsArray.some(subscribedProduct => subscriptionProductIDs?.includes(subscribedProduct))
                if (itemExistsInGroup && groupAlreadySubscribed) {
                    return group.groupName
                }
            }
        }
    }
    return false
}

export const isSubscriptionLineItem = (subscriptionProducts, productId) => {
    return  subscriptionProducts?.indexOf(productId) !== -1
}

export const getSubscriptionProducts = (basket) => {
    const optInJson = basket?.c_subscriptionOptins ? JSON.parse(basket.c_subscriptionOptins) : []
    return optInJson?.map(optin => optin?.product)?.filter(productId => basket?.productItems?.some(item => item?.productId === productId && !item?.c_og_bonusProductLineItem))
}

export const getBonusItem = (basket) => {
    return basket?.productItems?.find(item => item?.c_og_bonusProductLineItem)
}

export const getSKUsWithBonusEligibility = (basket) => {
    const optInJson = basket?.c_subscriptionOptins ? JSON.parse(basket.c_subscriptionOptins) : []
    return optInJson?.map(optin => optin?.product)?.filter(productId => basket?.productItems?.some(item => item?.productId === productId && item?.c_og_BonusItemEligible))
};

export const isSubscriptionNotExportedToOG = (orders) => {
    return orders?.some(order => !order.c_isSubscribedToOrderGroove && order?.productItems?.some(item => JSON.parse(order.c_subscriptionOptins || '[]')?.some(opt => opt?.product === item?.productId)))
}

export const getBakset = async (basketId, site, token, api) => {
    /*****  Note: Hooks can only be called inside of the body of a function component. We cannot use hook to get fresh basket. *****/
    const {clientConfig} = api.shopperExperience
    const basket = await fetch(`${clientConfig.proxy}/checkout/shopper-baskets/v1/organizations/${clientConfig.parameters.organizationId}/baskets/${basketId}?siteId=${site?.id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return await basket.json()
}

export const validateSubscription = async (currentBasket, customer, subscribedProducts) => {
    const subscriptions = getSubscriptionProducts(currentBasket)
    const isGuestWithSubscriptions = subscriptions?.length && customer?.isGuest
    const isMultipleSubsQty = isMultipleSubscriptionQty(currentBasket)
    const alreadySubscribed = isAlreadySubscribed(currentBasket, subscribedProducts)
    const bonusItem = getBonusItem(currentBasket)
    const bonusElibibleProducts = getSKUsWithBonusEligibility(currentBasket)

    return !(isGuestWithSubscriptions || isMultipleSubsQty || alreadySubscribed || (bonusElibibleProducts?.length && !bonusItem) || (!bonusElibibleProducts?.length && bonusItem) || (bonusItem?.quantity > 1))
}

export const validateShipment = (basket) => {
    const subscriptions = getSubscriptionProducts(basket)
    const stateCode = basket?.shipments?.[0]?.shippingAddress?.stateCode
    const shippingId = basket?.shipments?.[0]?.shippingMethod.id
    /*
     * Block checkout by adding safety checks if:
     * 1. Order is a subscription one and shipping method is Ground
     * 2. Order is a non-subscription one and shipping method is FreeShipping
     * 3. Order is a subscription one, shipping method is of FreeShipping and state is either HI or AK
     */

    if (subscriptions?.length && shippingId === 'Ground') {
        return false
    } else if (!subscriptions?.length && shippingId === 'FreeShipping') {
        return false
    } else if (subscriptions?.length && shippingId === 'FreeShipping' && STANDARD_SHIPPING_STATES.indexOf(stateCode) !== -1) {
        return false
    }
    return true
}

export const validateProductQuantities = (basket) => {
    return basket?.productItems?.some(item => item.quantity > 5);
}
